import URI from "urijs";
import useActiveState from "../../../lib/useActiveState";
import { useState } from "react";
import { useMount } from "ahooks";
import { useLocation } from "react-router-dom";
import uni from "@dcloudio/uni-webview-js";

let ios17 = false;
let res = navigator.userAgent.match(/OS (\d+).* like Mac OS/);
console.log(res);
if (res) {
  ios17 = res[1] >= 17;
}
console.warn(ios17);

export default ({ close, retry, getter }) => {
  const location = useLocation();
  const [key] = useActiveState("key");
  const [agent] = useActiveState("agent");
  const [confirm, setConfirm] = useState(ios17);
  let [udid, setUdid] = useActiveState("udid");

  useMount(() => {
    // 若需要弹出安装框，需等弹框内图片加载完成再进行页面跳转下载文件
    if (!confirm) {
      jumpCode(1);
    }
  });

  const jumpCode = (val) => {
    console.log(
      "有没有bd_vid或者uuid-----------------",
      localStorage.getItem("bd_vid"),
      localStorage.getItem("tgm_uuid")
    );

    const route = URI(
      (getter ? "/" : location.pathname) + location.search + location.hash
    )
      .addQuery("agent", agent)
      .addQuery("key", key)
      .addQuery("auto", 1)
      .addQuery("user_id", localStorage.getItem("user_id") || "")
      .addQuery("username", localStorage.getItem("username") || "")
      .addQuery("token", localStorage.getItem("token") || "")
      .addQuery("bd_vid", localStorage.getItem("bd_vid"))
      .addQuery("uuid", localStorage.getItem("tgm_uuid"))
      .toString();
    if (navigator.userAgent == "app") {
      window.location.href = URI("app://browser")
        .addQuery("url", route)
        .toString();
      return;
    }
    if (/bsl\//i.test(navigator.userAgent)) {
      BSL.OpenWeb(URI(window.location.href).hash(route).toString());
      return;
    }
    if (navigator.userAgent == "uniapp") {
      console.info("uniapp", URI(window.location.href).hash(route).toString());
      uni.postMessage({
        data: {
          type: "route",
          route: URI(window.location.href).hash(route).toString(),
        },
      });
      return;
    }

    if (!udid || !localStorage.getItem("desFileDown")) {
      // 获取udid
      window.location.href = URI("https://app.9917.cn/udid.php")
        .addQuery("url", URI(window.location.href).hash(route).toString())
        .addQuery("key", key)
        .toString();

      // iOS系统不为17及以上， iOS系统才可以通过 .mobileprovision 自动进入设置-描述文件安装处
      /* embedded.mobileprovision描述文件，里面包含了证书信息，如调试设备UDID、Entitlements、AppIDName、DeveloperCertificates等
      大量关于此安装包和证书的信息，此文件是二进制格式，无法直接打开，只能通过终端命令，例如mac下自动的命令行工具：security来查看信息。
      */
      console.log("udid----========", udid);
      console.log("进入getter页面");
      if (val === 1) {
        setTimeout(() => {
          window.location.href =
            "https://app.9917.cn/cert/embedded.mobileprovision";

          setTimeout(() => {
            let userAgent = window.navigator.userAgent;
            localStorage.setItem("desFileDown", userAgent);
          }, 2500);
        }, 2500);
      }
    }
  };

  return (
    <>
      {confirm ? (
        <>
          <div className="game-ios-guide">
            <div className="giu-container">
              <div className="giu-titlle">
                <div className="title-font">安装小贴士</div>
                <img
                  style={{ width: "16px", height: "16px" }}
                  src="/resources/game/close.png"
                  alt=""
                  onClick={() => setConfirm(false)}
                />
              </div>
              <div className="giu-tips" style={{ marginBottom: "15px" }}>
                请先按照如下提示安装描述文件后，即可下载游戏！
              </div>
              <div className="giu-img">
                <img
                  src="/resources/game/downloadTips.jpg"
                  alt=""
                  onLoad={() => {
                    jumpCode(2);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}
      {getter || (
        <div className="game-getter">
          <div className="gt-container">
            <div className="gt-head" onClick={close}></div>
            <div className="gt-body">
              安装至尊版游戏前须安装苹果系统描述文件。安装时点击“
              <span className="free">允许</span>”，切勿点击“
              <span className="warn">忽略</span>”
            </div>
            <div className="gt-foot">
              <div className="btn" onClick={retry}></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
