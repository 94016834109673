import {
  AndroidOutlined,
  AppleOutlined,
  CloseOutlined,
  DownOutlined,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import {
  useClickAway,
  useInViewport,
  useLocalStorageState,
  useLockFn,
  useScroll,
  useUpdateEffect,
} from "ahooks";
import { Link } from "react-router-dom";
import general from "../../general";
import dayjs from "dayjs";
import $ from "jquery";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import { CSSTransition } from "react-transition-group";

const sorts = [
  {
    sort: "publish",
    text: "最新发布",
  },
  {
    sort: "effective",
    text: "性价比高",
  },
  {
    sort: "asc",
    text: "价格升序",
  },
  {
    sort: "desc",
    text: "价格降序",
  },
];
const types = [
  {
    type: "1",
    text: "BT版",
  },
  {
    type: "2",
    text: "折扣",
  },
  {
    type: "3",
    text: "H5",
  },
];
const devices = [
  {
    device: "0",
    text: "双端",
  },
  {
    device: "2",
    text: "苹果",
  },
  {
    device: "1",
    text: "安卓",
  },
];
const initFilter = {
  type: null,
  device: null,
  minPrice: undefined,
  maxPrice: undefined,
  tag: undefined,
};

export default (props) => {
  const [sortIng, setSortIng] = useState();
  const [sort, setSort] = useState(0);
  const [filtering, setFiltering] = useState();
  const [filter, setFilter] = useState(initFilter);
  const [tmpFilter, setTmpFilter] = useState({});
  const [tags, setTags] = useState([]);
  const [kw, setKw] = useState("");
  const sortRef = useRef(null);
  useClickAway(() => {
    setSortIng(false);
  }, sortRef);

  const loadTag = useLockFn(async () => {
    Dialog.loading();
    general.axios
      .post("/base/game/getClassifyList")
      .finally(() => {
        Dialog.close();
      })
      .then((response) => {
        let { status, data } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setTags(data.game_classify_list[0].sub_classify_list[0]);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });
  useEffect(() => {
    if (filtering) {
      setTmpFilter(filter);
    }
    if (tags.length === 0 && filtering) {
      loadTag();
    }
  }, [filtering]);

  const [page, setPage] = useState(1);
  const [trades, setTrades] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    let data = {
      member_id: undefined,
      username: undefined,
      sort_type: sorts[sort].sort,
      game_species_type: filter.type !== null ? types[filter.type].type : "",
      game_device_type:
        filter.device !== null ? devices[filter.device].device : "",
      trade_featured: props.good ? 1 : 0,
      game_name: kw,
      pagination: { count: 20, page },
    };
    if (filter.minPrice && filter.maxPrice) {
      data.trade_price_range = filter.minPrice + "-" + filter.maxPrice;
    }
    if (tags.length > 0) {
      if (filter.tag !== undefined) {
        data.game_classify_id = tags[filter.tag].game_classify_id;
      }
    }
    if (parms.page) {
      data.pagination.page = parms.page;
    }
    await general.axios
      .post("/user/trade/getTradeList", data)
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let {
          status,
          data: { account_list, paginated },
        } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        if (parms.page) {
          setPage(parms.page);
        }
        setTrades((trades) =>
          parms.page === 1 || !parms.page
            ? account_list
            : [...trades, ...account_list]
        );
        setMore(paginated.more && account_list.length > 0);
      })
      .catch((err) => {
        Dialog.error("getTradeList " + err.message);
      });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    load({ page: 1 });
  }, [sort, filter, kw]);

  const scroll = useScroll();
  useUpdateEffect(() => {
    if (props.active) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  const headRef = useRef(null);
  const headInViewPort = useInViewport(headRef);

  const [style, setStyle] = useLocalStorageState("trade-style", {
    defaultValue: "list",
  });

  return (
    <>
      <div className="trade-bar width">
        <div className="container">
          <div
            className="sort"
            onClick={() => setSortIng(!sortIng)}
            ref={sortRef}
          >
            {sorts[sort].text}
            <DownOutlined className="icon" />
          </div>
          <CSSTransition in={sortIng} timeout={250} unmountOnExit={true}>
            <div className="sorts">
              {sorts.map((item, index) => (
                <div
                  key={index}
                  className={"item" + (sort === index ? " active" : "")}
                  onClick={() => {
                    setSort(index);
                    setSortIng(false);
                  }}
                >
                  {item.text}
                </div>
              ))}
            </div>
          </CSSTransition>
          <FilterOutlined
            className="filter"
            onClick={() => setFiltering(true)}
          />
          <div className="input">
            <SearchOutlined className="icon" style={{ paddingRight: "5px" }} />
            <input
              type="text"
              placeholder="输入关键字搜索"
              value={kw}
              onChange={(e) => setKw(e.target.value)}
            />
            {kw ? (
              <CloseOutlined className="close" onClick={() => setKw("")} />
            ) : null}
          </div>
          {style == "block" ? (
            <div
              className="style"
              onClick={() => {
                window.scrollTo(0, 0);
                setStyle("list");
              }}
            >
              <img src="/resources/trade/icon-block.png" alt="" />
            </div>
          ) : null}
          {style == "list" ? (
            <div
              className="style"
              onClick={() => {
                window.scrollTo(0, 0);
                setStyle("block");
              }}
            >
              <img src="/resources/trade/icon-list.png" alt="" />
            </div>
          ) : null}
        </div>
      </div>
      <div className={`trade-list trade-list-${style}`}>
        {trades.map((trade) => (
          <Link
            key={trade.trade_id}
            to={"/trade/detail?id=" + trade.trade_id}
            className={`item item-${style}`}
          >
            <div className="body">
              <div className="main">
                <div className="icon">
                  <Image src={trade.account_icon} alt="" />
                </div>
                <div className="info">
                  <div className="name">
                    <div className={"type type-" + trade.game_species_type} />
                    <div className="named">
                      {trade.game_name_main || trade.game_name}
                    </div>
                  </div>
                  {trade.game_name_branch ? (
                    <div className="branch">{trade.game_name_branch}</div>
                  ) : null}
                  <div className="title">
                    {trade.game_device_type != 1 ? (
                      <AppleOutlined className="ios" />
                    ) : null}
                    {trade.game_device_type != 2 ? (
                      <AndroidOutlined className="android" />
                    ) : null}
                    <div className="titled">{trade.title}</div>
                  </div>
                </div>
              </div>
              <div className="data">
                <div className="time">
                  上架时间：
                  {dayjs.unix(trade.sell_time).format("YYYY-MM-DD HH:mm:ss")}
                </div>
                <div className="prices">
                  <div className="price">{trade.trade_price}</div>
                  <div className="amount">{trade.total_amount}</div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      {trades.length > 0 && !more ? (
        <div className="view-end">别撩啦！已经到底啦~</div>
      ) : null}
      <CSSTransition in={filtering} timeout={250} unmountOnExit={true}>
        <div className="trade-filter">
          <div className="mask" onClick={() => setFiltering(false)} />
          <div className="container">
            <div className="title">游戏专区</div>
            <div className="type">
              {types.map((item, index) => (
                <div
                  key={index}
                  className={
                    "item" + (index === tmpFilter.type ? " active" : "")
                  }
                  onClick={() => setTmpFilter({ ...tmpFilter, type: index })}
                >
                  {item.text}
                </div>
              ))}
            </div>
            <div className="title">设备</div>
            <div className="type">
              {devices.map((item, index) => (
                <div
                  key={index}
                  className={
                    "item" + (index === tmpFilter.device ? " active" : "")
                  }
                  onClick={() => setTmpFilter({ ...tmpFilter, device: index })}
                >
                  {item.text}
                </div>
              ))}
            </div>
            <div className="title">价格区间</div>
            <div className="prices">
              <input
                type="text"
                placeholder="最低价格"
                value={tmpFilter.minPrice}
                onChange={(e) =>
                  setTmpFilter({ ...tmpFilter, minPrice: e.target.value })
                }
              />
              <span>~</span>
              <input
                type="text"
                placeholder="最高价格"
                value={tmpFilter.maxPrice}
                onChange={(e) =>
                  setTmpFilter({ ...tmpFilter, maxPrice: e.target.value })
                }
              />
            </div>
            <div className="title">游戏分类</div>
            <div className="tag">
              <div
                className={
                  "item" + (tmpFilter.tag === undefined ? " active" : "")
                }
                onClick={() => setTmpFilter({ ...tmpFilter, tag: undefined })}
              >
                <span>全部</span>
              </div>
              {tags.map((item, index) => (
                <div
                  key={index}
                  className={
                    "item" + (index === tmpFilter.tag ? " active" : "")
                  }
                  onClick={() => setTmpFilter({ ...tmpFilter, tag: index })}
                >
                  <span>{item.game_classify_name}</span>
                </div>
              ))}
            </div>
            <div className="operate">
              <div className="btn" onClick={() => setTmpFilter(initFilter)}>
                重置
              </div>
              <div
                className="btn"
                onClick={() => {
                  setFiltering(false);
                  setFilter({ ...tmpFilter });
                }}
              >
                确定
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
