import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMount, useReactive } from "ahooks";
import URI from "urijs";
import useActiveState from "../../../lib/useActiveState";
import Dialog from "../../../lib/Dialog";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Getter from "./Getter";
import Guide from "./Guide";
import Reserve from "./Reserve";
import Tips from "./Tips";
import Image from "../../../lib/Image";
import CryptoJS from "crypto-js";

const { aplus_queue } = window;

const Main = ({ game, close }) => {
  const [app] = useActiveState("app");
  const [udid] = useActiveState("udid");
  const data = useReactive({
    udidGetter: undefined,
    interval: undefined,
    state: undefined,
    reserve: undefined,
    tips: undefined,
    url: undefined,
    frequency: 1,
  });

  const history = useHistory();

  let [progressNum, setProgressNum] = useState(0);
  let [record, setRecord] = useState([]);
  const [downUrl, setDownUrl] = useState("");
  let eventSource = null;
  const isClose = useRef(false); // 取消下载
  // useMount(() => {
  //   if (udid) {
  //     data.interval = 3000;
  //     connectWebSocket();

  //     aplus_queue.push({
  //       action: "aplus.record",
  //       arguments: [
  //         "download",
  //         "download",
  //         {
  //           type: "vip",
  //           state: "start",
  //           gameId: game?.game_id,
  //           agent: localStorage.getItem("agent"),
  //           username: localStorage.getItem("username"),
  //         },
  //       ],
  //     });
  //   }
  // });

  useEffect(() => {
    if (udid && localStorage.getItem("desFileDown")) {
      data.interval = 3000;
      connectWebSocket();

      aplus_queue.push({
        action: "aplus.record",
        arguments: [
          "download",
          "download",
          {
            type: "vip",
            state: "start",
            gameId: game?.game_id,
            agent: localStorage.getItem("agent"),
            username: localStorage.getItem("username"),
          },
        ],
      });
    }

    return () => {
      // 在组件卸载时关闭 EventSource 连接
      if (eventSource) {
        isClose.current = true;
        eventSource.close();
        Dialog.close();
        close();
      }
    };
  }, []);

  const connectWebSocket = () => {
    Dialog.close();
    Dialog.loading();
    let appId = game?.game_url?.super_ios_url.split("app_id")[1].split("/")[1];
    console.log("appId---------", appId);

    if (!game?.maiyou_gameid) {
      Dialog.error("缺少必要参数gameid，请重试");
      return;
    }

    if (!udid) {
      Dialog.error("缺少必要参数udid，请重试");
      return;
    }

    const userAgent = window.navigator.userAgent;
    let isIos = userAgent.match(/iphone os|ipad|mac os/i);

    let tgmag = localStorage.getItem("agent") || "x7z1";

    let tgmudid = localStorage.getItem("tgm_uuid")
      ? tgmag + "~" + localStorage.getItem("tgm_uuid")
      : tgmag;

    if (localStorage.getItem("tgm_uuid")) {
      // 密钥和明文
      var key = "1234567890123456"; // 密钥长度需为16字节（128位）
      var plaintext = tgmudid;

      // 确保密钥长度为16字节
      key = CryptoJS.enc.Utf8.parse(key);

      // 加密数据
      var encrypted = CryptoJS.AES.encrypt(plaintext, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });

      // 转换为十六进制字符串（bin2hex 类似操作）
      var encryptedHex = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
      console.log("Encrypted (Hex):", encryptedHex);

      tgmudid = encryptedHex;
    }

    let params = `game_id=${game?.maiyou_gameid}&app_id=${
      appId || ""
    }&agent=${tgmudid}&user_id=${
      localStorage.getItem("user_id") || 1
    }&username=${
      localStorage.getItem("username") || 1
    }&http_udid=${udid}&device_type=iPhone&is_mac=${
      isIos ? "ios" : "android"
    }&token=${localStorage.getItem("token")}${
      localStorage.getItem("tgm_uuid") ? "&type=tgm" : ""
    }`;

    console.log("params---------", params);

    // iossupersign.9917.cn   cdniossupersign.9917.cn  wss://iossupersign.9917.cn/api/v1/process
    // http://192.168.100.14:9900
    eventSource = new EventSource(
      "https://middle-api.9917.cn/v1/events?" + params
    );

    // 断开连接重连
    eventSource.onclose = (event) => {
      console.log("连接断开", event);
    };

    // 出错重连
    eventSource.onerror = (event) => {
      console.log("连接出错", event);

      console.log("isclose------------", isClose.current, data.frequency);
      if (isClose.current === false && record[record.length - 1] !== 100) {
        if (data.frequency < 4) {
          setTimeout(() => {
            data.frequency += 1;
            Dialog.info("连接出错，正在重新连接");

            setTimeout(() => {
              connectWebSocket();
            }, 1000);
          }, 3000);
        } else {
          Dialog.close();
          Dialog.error("连接失败，请检查网络情况，稍后重试~");
          isClose.current = true;
          eventSource?.close();

          setTimeout(() => {
            data.interval = undefined;
            console.log(eventSource);
            close();
          }, 1500);
        }
      }
    };

    // 当连接被打开时，注册接收消息的处理函数
    eventSource.onopen = () => {
      Dialog.close();
      console.log("打开啦----------");
    };

    eventSource.onmessage = (event) => {
      console.log("接收到消息-----", JSON.parse(event.data));
    };

    eventSource.addEventListener(
      "sub_package",
      (event) => {
        console.log("触发对应事件-----", JSON.parse(event.data));

        const data = JSON.parse(event.data);
        if (data.code === 200) {
          // 后端分包进度
          let num = data.percentage.toFixed(0);

          let n = 6;
          let size = game?.game_size?.ios_size?.split(" ")[0];
          if (size < 50) {
            n = Math.ceil(Math.random() * 13) + 13;
          } else if (size < 150) {
            n = Math.ceil(Math.random() * 10) + 10;
          } else if (size < 500) {
            n = Math.ceil(Math.random() * 8) + 8;
          } else if (size < 1000) {
            n = Math.ceil(Math.random() * 4) + 4;
          } else {
            n = Math.ceil(Math.random() * 2) + 2;
          }
          console.log("nnnnnnnnnnnnnnnnnnnnn------", n);
          //前端虚拟当前要展示的进度
          let progress_bar_data = progressNum + n;

          // 后端分包进度小于99，走虚拟进度条
          if (num < 99) {
            // 虚拟进度条小于99才展示，大于就不再继续赋值增加后的数值了
            if (progress_bar_data < 99) {
              progressNum = progress_bar_data;
              setProgressNum(progress_bar_data);
            }
          } else {
            progressNum = num;
            setProgressNum(num);
          }

          record.push(data.percentage);
          setRecord([...record]);

          if (record[0] !== 100) {
            console.log(record);
            console.log("展示进度条----------");
            setDownUrl("show");
          }

          // 若点击下载后  下载链接有值  直接访问链接下载游戏  否则说明需要分包 则走进度条
          if (data.download) {
            console.log(data.download);
            aplus_queue.push({
              action: "aplus.record",
              arguments: [
                "download",
                "download",
                {
                  type: "vip",
                  state: "success",
                  gameId: game?.game_id,
                  agent: localStorage.getItem("agent"),
                  username: localStorage.getItem("username"),
                },
              ],
            });

            data.interval = undefined;

            isClose.current = true;
            eventSource?.close();
            // setTimeout(() => {
            Dialog.close();
            close();
            // }, 1000);

            if (navigator.userAgent == "app") {
              window.location.href = URI("app://browser")
                .addQuery("url", data.download)
                .toString();
            } else {
              window.location.href = data.download;
            }
          } else {
            if (record[record.length - 1] === 100) {
              connectWebSocket();
            }
          }
        } else {
          Dialog.info(data.message);
          isClose.current = true;

          setTimeout(() => {
            eventSource?.close();
            close();
          }, 2000);
        }
      },
      false
    );
  };

  // useEffect(() => {
  //   history.listen((location) => {
  //     // console.log("请求路由路径变化了", location);

  //     isClose.current = true;
  //     eventSource?.close();
  //     data.interval = undefined;
  //     close();
  //   });
  // });

  const cancelDown = () => {
    isClose.current = true;
    eventSource?.close();
    data.interval = undefined;
    close();
  };

  return (
    <>
      {udid && localStorage.getItem("desFileDown") ? (
        <>
          {data.interval && downUrl ? (
            <TransitionGroup>
              <CSSTransition timeout={250}>
                <div className="game-new-super">
                  <div className="new-super-main">
                    <div
                      className="cancel-btn"
                      onClick={() => {
                        Dialog.affirm(
                          "温馨提示",
                          <div style={{ textAlign: "center" }}>
                            您确定取消游戏下载？
                          </div>,
                          cancelDown,
                          () => {
                            Dialog.close();
                          },
                          {
                            close: "确定",
                            affirm: "继续等待",
                          }
                        );
                      }}
                    >
                      取消
                    </div>
                    <div className="super-game-msg">
                      <Image src={game?.game_image?.thumb} />
                      <div className="name">
                        {game?.game_name_main || game?.game_name}
                        {game?.game_name_branch &&
                          " - " + game?.game_name_branch}
                      </div>
                    </div>
                    <div className="super-game-progress">
                      <div
                        className="progress"
                        id="progress"
                        style={{ width: progressNum + "%" }}
                      ></div>
                      <div className="font">
                        分包签名中，勿退出{progressNum}%
                      </div>
                    </div>
                    <div className="tips">
                      <ExclamationCircleOutlined
                        style={{ marginRight: "2px", fontSize: "0.28rem" }}
                      />
                      请不要离开此页面并保持屏幕常亮
                    </div>
                    <div className="udid-msg">
                      <span>UDID：{udid}</span>

                      <CopyToClipboard
                        text={udid}
                        onCopy={() => Dialog.info("已复制")}
                      >
                        <p>复制</p>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </TransitionGroup>
          ) : null}
        </>
      ) : (
        <>
          <Getter close={close} />
        </>
      )}
      {data.reserve ? (
        <>
          <Reserve game={game} close={close} />
        </>
      ) : null}
      {data.tips ? (
        <>
          <Tips url={data.url} close={close} />
        </>
      ) : null}
    </>
  );
};

export default (props) => {
  const [udid] = useActiveState("udid");

  return (
    <>
      {udid && localStorage.getItem("desFileDown") && (
        <Guide>
          <Main {...props} />
        </Guide>
      )}
    </>
  );
};
