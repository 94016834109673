const env = process?.env || {};

export const name = env.REACT_APP_NAME;
export const version = env.REACT_APP_VERSION;

env.REACT_APP_ENV = env.REACT_APP_ENV || env.NODE_ENV;

export const dev = env.REACT_APP_ENV.startsWith("dev");
export const test = env.REACT_APP_ENV.startsWith("test");
export const prod =
  env.REACT_APP_ENV.startsWith("pro") && !location.host.startsWith("test");

export const url = env.REACT_APP_URL || "https://app-api.bestgame99.com/v3";
export const sysUrl = env.REACT_APP_SYS_URL || "https://sys.9917.cn";
export const ocpcUrl = env.REACT_APP_OCPC_URL || "https://tgm.9917.cn";
export const localUrl =
  env.REACT_APP_LOCAL_URL || "http://192.168.110.73:10011";

console.log(env.REACT_APP_URL);
console.info("config", { name, version, env, dev, test, prod, url, sysUrl });
