import general from "../general";
import { LeftOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import KeepScroll from "./KeepScroll";
import { useScroll, useSize, useThrottleFn } from "ahooks";
import { Link, useHistory, useLocation } from "react-router-dom";
import URI from "urijs";
import ActiveContext from "../lib/ActiveContext";
import $ from "jquery";
import useActiveState from "./useActiveState";

export const NavLink = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [initProps, setInitProps] = useState({});
  const [newProps, setNewProps] = useState({});
  useEffect(() => {
    setInitProps({ user: true, token: true, escape: false, ...props });
  }, [props]);
  useEffect(() => {
    let to = props.to;
    if (to) {
      to = URI(to).addQuery("origin", "react").toString();

      if (
        initProps.iframe &&
        localStorage.getItem("agent") &&
        !initProps.escape
      ) {
        to = URI(to)
          .addQuery("agent", localStorage.getItem("agent"))
          .toString();
      }
      if (
        initProps.iframe &&
        localStorage.getItem("app") &&
        JSON.parse(localStorage.getItem("app")).brand
      ) {
        to = URI(to)
          .addQuery("brand", JSON.parse(localStorage.getItem("app")).brand)
          .toString();
      }
      if (initProps.iframe && initProps.token) {
        to = URI(to)
          .addQuery("username", localStorage.getItem("username"))
          .addQuery("token", localStorage.getItem("token"))
          .toString();
      }
      if (initProps.iframe) {
        let uri = URI("/iframe");
        uri.addQuery("url", to);
        if (initProps.iframe.title) {
          uri.addQuery("title", initProps.iframe.title);
        }

        if (initProps.iframe.topHidden) {
          uri.addQuery("topHidden", initProps.iframe.topHidden);
        }
        to = uri.toString();
      }
    }
    setNewProps({
      ...props,
      to: to ? to : "",
      iframe: null,
      user: null,
      token: null,
      escape: null,
      onClick: null,
    });
    // console.log("to===================", newProps);
  }, [initProps]);
  const [udid] = useActiveState("udid");
  const [require, setRequire] = useState();
  const onClick = (e) => {
    if (initProps.user && !general.isLogin()) {
      e.preventDefault();
      e.stopPropagation();
      history.push(
        URI("/login")
          .addQuery(
            "callback",
            location.pathname + location.search + location.hash
          )
          .toString()
      );
    } /*else if (
      !udid
      && props.to
      && props.to.startsWith('http')
      && (
        props.to.indexOf('/home/monthCard') >= 0
        || props.to.indexOf('/home/MonthCard') >= 0
        || props.to.indexOf('/home/newuser') >= 0
      )
    ) {
      setRequire(<Require reject={() => setRequire(null)} getter={Getter}/>);
      e.preventDefault();
      e.stopPropagation();
    }*/ else {
      if (props.onClick) {
        props.onClick(e);
      }
    }
  };

  return (
    <>
      {require}
      {props.to ? (
        <>
          {newProps.to ? (
            <Link className={props.className} {...newProps} onClick={onClick}>
              {props.children}
            </Link>
          ) : null}
        </>
      ) : (
        <a className={props.className} {...newProps} onClick={onClick}>
          {props.children}
        </a>
      )}
    </>
  );
};

export const NavView = (props) => {
  const [title, setTitle] = useState(props.title);
  const [colour, setColour] = useState();
  const [operate, setOperate] = useState();

  const scroll = useScroll();
  const { run: _setColour } = useThrottleFn(
    (value) => {
      setColour(value);
    },
    { wait: 10 }
  );
  useEffect(() => {
    if (props.active && props.color) {
      if (scroll && scroll.top) {
        let opacity = Math.abs(scroll.top) / 40;
        if (opacity > 1) {
          opacity = 1;
        }
        _setColour("rgba(" + props.color + ", " + opacity + ")");
        // _setColour("rgba(" + props.color + ")");
      } else {
        _setColour("transparent");
        // _setColour("rgba(" + props.color + ")");
      }
    }
  }, [scroll, props.active]);

  const backRef = useRef();
  const operateRef = useRef();
  const backSize = useSize(backRef);
  const operateSize = useSize(operateRef);

  useEffect(() => {
    if (operateSize?.width > backSize?.width) {
      if (backRef.current) {
        $(backRef.current).css("width", operateSize.width - 10);
      }
    }
  }, [backSize, operateSize]);

  return (
    <ActiveContext.Provider value={props.active}>
      <KeepScroll active={props.active}>
        <div
          className={`nav-view ${props.invasion ? "invasion " : ""} ${
            props.active ? "active" : ""
          } ${props.className || ""}`}
          style={{ zIndex: props.index }}
        >
          {/*  || props.query.topHidden */}
          {props.topHidden ? null : (
            <div
              className={"nav-view-head width " + props.theme}
              style={colour ? { backgroundColor: colour } : null}
            >
              {props.statusBarBackground !== false ? (
                <div
                  className="status-bar-background"
                  style={{
                    backgroundImage: props.backColor
                      ? "linear-gradient(#202737, #202737)"
                      : "linear-gradient(rgba(204, 204, 204, 0.95), rgba(204, 204, 204, 0))",
                  }}
                />
              ) : null}
              <div className="container">
                {props.back !== false ? (
                  // ref={backRef}
                  <div
                    ref={backRef}
                    className="back"
                    style={{
                      color: props.backColor ? props.backColor : "#3d3d3d",
                    }}
                    onClick={() => general.history.goBack()}
                  >
                    <LeftOutlined />
                  </div>
                ) : null}
                <div className="title">{title}</div>
                <div className="operate" ref={operateRef}>
                  {operate}
                </div>
              </div>
            </div>
          )}

          {/*  || props.query.topHidden */}
          <div
            className="nav-view-body"
            style={{
              background: props.background,
              paddingTop:
                props.topHidden || props.invasion
                  ? 0
                  : "calc(env(safe-area-inset-top) + 48px)",
            }}
          >
            <props.component
              active={props.active}
              action={props.action}
              query={props.query}
              setTitle={setTitle}
              setColour={setColour}
              setOperate={setOperate}
            />
          </div>
        </div>
      </KeepScroll>
    </ActiveContext.Provider>
  );
};

export default {};
